import React from "react";
import AwardsAndCertificates from "../../components/main-components/awards-certificates";
import Banner from "../../components/main-components/banner";
import Coins from "../../components/main-components/coins";
import Faqs from "../../components/main-components/faqs";
import Features from "../../components/main-components/features";
import Invitation from "../../components/main-components/invitation";
import OurServices from "../../components/main-components/our-services";
import Statistics from "../../components/main-components/statistics";
import CustomizedSteppers from "../../components/main-components/steps";

function LandingPage() {
    return (
        <div>
            <Banner />
            <Invitation />
            <Statistics />
            <Features />
            <OurServices />
            <Coins />
            <CustomizedSteppers />
            <Faqs />
            <AwardsAndCertificates />
        </div>
    );
}
export default LandingPage;
