import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Litecoin from "./components/litecoin";

// const Planets = () => {
//   return (
//     <div>
//       <Carousel
//         showStatus={false}
//         showIndicators={false}
//         autoPlay
//         interval={5000}
//         infiniteLoop
//         showArrows={false}
//       >
//         <Ada />
//       </Carousel>
//     </div>
//   );
// };

const Banner = () => {
  return <Litecoin />;
};
export default Banner;
