import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

function ListItemLink(props) {
    const { icon, primary, to, className, target } = props;

    const CustomLink = React.useMemo(
        () =>
            React.forwardRef((linkProps, ref) => (
                <Link ref={ref} to={to} {...linkProps} />
            )),
        [to]
    );

    const Atag = React.useMemo(
        () =>
            React.forwardRef((linkProps, ref) => (
                <a
                    ref={ref}
                    href={to}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                        display: "flex",
                        margin: "5px 0px",
                        paddingRight: "18px",
                        paddingLeft: "18px",
                        textDecoration: "none",
                        color: "inherit",
                    }}
                >
                    {linkProps.children}
                </a>
            )),
        [to]
    );

    return (
        <li className={className}>
            <ListItem button component={target ? Atag : CustomLink}>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={primary} />
            </ListItem>
        </li>
    );
}
ListItemLink.propTypes = {
    icon: PropTypes.element,
    primary: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
};

export default ListItemLink;
