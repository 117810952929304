const AuthActionTypes = {
  AUTH_USER_START: 'AUTH_USER_START',
  AUTH_USER_SUCCESS: 'AUTH_USER_SUCCESS',
  AUTH_USER_FAILURE: 'AUTH_USER_FAILURE',

  USER_CREATE_START: 'USER_CREATE_START',
  USER_CREATE_SUCCESS: 'USER_CREATE_SUCCESS',
  USER_CREATE_FAILURE: 'USER_CREATE_FAILURE',

  GET_USER_DETAILS_START: 'GET_USER_DETAILS_START',
  GET_USER_DETAILS_SUCCESS: 'GET_USER_DETAILS_SUCCESS',
  GET_USER_DETAILS_FAILURE: 'GET_USER_DETAILS_FAILURE',

  UPDATE_USER_DETAILS_START: 'UPDATE_USER_DETAILS_START',
  UPDATE_USER_DETAILS_SUCCESS: 'UPDATE_USER_DETAILS_SUCCESS',
  UPDATE_USER_DETAILS_FAILURE: 'UPDATE_USER_DETAILS_FAILURE',

  FORGOT_PASSWORD_START: 'FORGOT_PASSWORD_START',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FAILURE: 'FORGOT_PASSWORD_FAILURE',

  UPDATE_AUTH_PASSWORD_START: 'UPDATE_AUTH_PASSWORD_START',
  UPDATE_AUTH_PASSWORD_SUCCESS: 'UPDATE_AUTH_PASSWORD_SUCCESS',
  UPDATE_AUTH_PASSWORD_FAILURE: 'UPDATE_AUTH_PASSWORD_FAILURE',

  CLEAR_AUTH_MESSAGES: 'CLEAR_AUTH_MESSAGES',
};
export default AuthActionTypes;
