import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { QueryClient, QueryClientProvider } from "react-query";
import { ThemeProvider } from "@material-ui/core";
import theme from "./utils/theme";
import routes, { renderRoutes } from "./routes";
import { createStyles, makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import {
    getUserDetailsStartAsync,
    clearAuthMessages,
} from "./redux/auth/auth.actions";

const useStyles = makeStyles(() =>
    createStyles({
        "@global": {
            "*": {
                boxSizing: "border-box",
                margin: 0,
                padding: 0,
            },
            html: {
                "scroll-behavior": "smooth",
                "-webkit-font-smoothing": "antialiased",
                "-moz-osx-font-smoothing": "grayscale",
                height: "100%",
                width: "100%",
            },
            body: {
                height: "100%",
                width: "100%",
            },
            "#root": {
                height: "100%",
                width: "100%",
            },
        },
    })
);
const queryClient = new QueryClient();

const GlobalStyles = () => {
    useStyles();
    return null;
};

const App = ({
    getUserDetailsStartAsync,
    clearAuthMessages,
    data,
    role,
    id,
}) => {
    useEffect(() => {
        if (id && role && !data) {
            getUserDetailsStartAsync(id);
        }
        setTimeout(() => {
            clearAuthMessages();
        }, 1000);
    }, [clearAuthMessages, getUserDetailsStartAsync, id, role, data]);

    return (
        <ThemeProvider theme={theme}>
            <QueryClientProvider client={queryClient}>
                <SnackbarProvider
                    dense
                    maxSnack={3}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    preventDuplicate
                >
                    <Router>
                        <GlobalStyles />
                        {renderRoutes(routes)}
                    </Router>
                </SnackbarProvider>
            </QueryClientProvider>
        </ThemeProvider>
    );
};

const mapDispatchToProps = (dispatch) => ({
    getUserDetailsStartAsync: (id) => dispatch(getUserDetailsStartAsync(id)),
    clearAuthMessages: () => dispatch(clearAuthMessages()),
});

const mapStateToProps = (state) => ({
    role: state.auth.role,
    id: state.auth.id,
    data: state.auth.data,
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
