import React from 'react';
import LandingNavBar from './top-bar';
import LandingFooter from './footer';
import { Fab, Link } from '@material-ui/core';
import { WhatsApp } from '@material-ui/icons';

const Layout = ({ children }) => (
  <div>
    <LandingNavBar />
    {children}
    <Link
      href="https://wa.me/+19292869528"
      target="_blank"
      rel="noreferrer noopener"
    >
      <Fab
        color="primary"
        aria-label="add"
        style={{ position: 'fixed', bottom: '3%', left: '5%' }}
      >
        <WhatsApp />
      </Fab>
    </Link>
    ;
    <LandingFooter />
  </div>
);
export default Layout;
