const UsersActionTypes = {
  GET_ALL_USER_DETAILS_START: "GET_ALL_USER_DETAILS_START",
  GET_ALL_USER_DETAILS_SUCCESS: "GET_ALL_USER_DETAILS_SUCCESS",
  GET_ALL_USER_DETAILS_FAILURE: "GET_ALL_USER_DETAILS_FAILURE",

  UPDATE_USER_START: "UPDATE_USER_START",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
  UPDATE_USER_FAILURE: "UPDATE_USER_FAILURE",

  DELETE_USER_START: "DELETE_USER_START",
  DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",
  DELETE_USER_FAILURE: "DELETE_USER_FAILURE",

  CLEAR_USER_MESSAGES: "CLEAR_USER_MESSAGES",
};
export default UsersActionTypes;
