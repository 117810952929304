import React from "react";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PeopleIcon from "@material-ui/icons/People";
import BarChartIcon from "@material-ui/icons/BarChart";
import ListItemLink from "../../../components/shared-components/link";
import Settings from "@material-ui/icons/Settings";
import MonetizationOnOutlined from "@material-ui/icons/MonetizationOnOutlined";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import VerticalAlignBottomIcon from "@material-ui/icons/VerticalAlignBottom";

const NavigationList = [
    { title: "Dashboard", to: "/app/dashboard", icon: <DashboardIcon /> },
    { title: "Cards", to: "/app/cards", icon: <CreditCardIcon /> },
    { title: "Users", to: "/app/users", icon: <PeopleIcon /> },
    {
        title: "Deposited Users",
        to: "/app/deposited",
        icon: <MonetizationOnOutlined />,
    },
    { title: "Transactions", to: "/app/transactions", icon: <BarChartIcon /> },
    {
        title: "Deposits",
        to: "/app/deposits",
        icon: <VerticalAlignBottomIcon />,
    },
    { title: "Settings", to: "/app/settings", icon: <Settings /> },
    {
        title: "Address",
        to: "/app/address",
        icon: <AccountBalanceWalletIcon />,
    },
];

const mainListItems = (
    <div>
        {NavigationList.map((navigation) => (
            <ListItemLink
                key={navigation.to}
                primary={navigation.title}
                to={navigation.to}
                icon={navigation.icon}
            />
        ))}
    </div>
);
export default mainListItems;
