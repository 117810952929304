const TransactionActionTypes = {
  CREATE_TRANSACTION_START: "CREATE_TRANSACTION_START",
  CREATE_TRANSACTION_SUCCESS: "CREATE_TRANSACTION_SUCCESS",
  CREATE_TRANSACTION_FAILURE: "CREATE_TRANSACTION_FAILURE",

  GET_TRANSACTIONS_START: "GET_TRANSACTIONS_START",
  GET_TRANSACTIONS_SUCCESS: "GET_TRANSACTIONS_SUCCESS",
  GET_TRANSACTIONS_FAILURE: "GET_TRANSACTIONS_FAILURE",

  UPDATE_TRANSACTION_START: "UPDATE_TRANSACTION_START",
  UPDATE_TRANSACTION_SUCCESS: "UPDATE_TRANSACTION_SUCCESS",
  UPDATE_TRANSACTION_FAILURE: "UPDATE_TRANSACTION_FAILURE",

  DELETE_TRANSACTION_START: "DELETE_TRANSACTION_START",
  DELETE_TRANSACTION_SUCCESS: "DELETE_TRANSACTION_SUCCESS",
  DELETE_TRANSACTION_FAILURE: "DELETE_TRANSACTION_FAILURE",

  GET_ALL_TRANSACTIONS_START: "GET_ALL_TRANSACTIONS_START",
  GET_ALL_TRANSACTIONS_SUCCESS: "GET_ALL_TRANSACTIONS_SUCCESS",
  GET_ALL_TRANSACTIONS_FAILURE: "GET_ALL_TRANSACTIONS_FAILURE",

  CLEAR_TRANSACTION_MESSAGES: "CLEAR_TRANSACTION_MESSAGES"
};
export default TransactionActionTypes;