const CardAccountTypes = {
  GET_CARD_DETAILS_START: 'GET_CARD_DETAILS_START',
  GET_CARD_DETAILS_SUCCESS: 'GET_CARD_DETAILS_SUCCESS',
  GET_CARD_DETAILS_FAILURE: 'GET_CARD_DETAILS_FAILURE',

  CARD_CREATE_START: 'CARD_CREATE_START',
  CARD_CREATE_SUCCESS: 'CARD_CREATE_SUCCESS',
  CARD_CREATE_FAILURE: 'CARD_CREATE_FAILURE',

  CARD_UPDATE_START: 'CARD_UPDATE_START',
  CARD_UPDATE_SUCCESS: 'CARD_UPDATE_SUCCESS',
  CARD_UPDATE_FAILURE: 'CARD_UPDATE_FAILURE',

  GET_ALL_CARDS_START: 'GET_ALL_CARDS_START',
  GET_ALL_CARDS_SUCCESS: 'GET_ALL_CARDS_SUCCESS',
  GET_ALL_CARDS_FAILURE: 'GET_ALL_CARDS_FAILURE',

  CLEAR_CARD_MESSAGES: 'CLEAR_CARD_MESSAGES',
};
export default CardAccountTypes;
