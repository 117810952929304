import CardAccountTypes from './card.types';

const initialState = {
  isFetching: false,
  errorMessage: null,
  data: null,
  message: null,
};

const cardReducer = (state = initialState, action) => {
  switch (action.type) {
    case CardAccountTypes.CLEAR_CARD_MESSAGES:
      return {
        ...state,
        message: null,
        errorMessage: null,
      };
    case CardAccountTypes.GET_CARD_DETAILS_START:
    case CardAccountTypes.CARD_CREATE_START:
    case CardAccountTypes.CARD_UPDATE_START:
    case CardAccountTypes.GET_ALL_CARDS_START:
      return {
        ...state,
        isFetching: true,
        message: null,
        errorMessage: null,
      };
    case CardAccountTypes.GET_CARD_DETAILS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload,
      };

    case CardAccountTypes.CARD_CREATE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload.card,
        message: action.payload.message,
      };

    case CardAccountTypes.CARD_UPDATE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: { ...state.data, ...action.payload },
      };

    case CardAccountTypes.GET_ALL_CARDS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: { ...action.payload },
      };
    case CardAccountTypes.CARD_UPDATE_FAILURE:
    case CardAccountTypes.CARD_CREATE_FAILURE:
    case CardAccountTypes.GET_CARD_DETAILS_FAILURE:
    case CardAccountTypes.GET_ALL_CARDS_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
        message: null,
        data: [],
      };
    default:
      return state;
  }
};
export default cardReducer;
